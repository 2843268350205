<template>
  <div class="card mb-0">
    <div :class="['card-body', { 'min-h-500px': isLoading }]">
      <px-loader v-if="isLoading" :is-full-window="false" bg="blue" />
      <template v-else>
        <template v-if="hasSigned">
          <div class="d-flex flex-wrap flex-stack mb-6">
            <div class="d-flex align-items-center mb-6 mb-md-0">
              <px-select
                v-model="showingScopeType"
                classes="form-select form-select-solid form-select-sm w-200px pe-11"
                placeholder="Select Scope Type"
                track-by="id"
                :items="scopeTypes"
                text-by="attributes.title"
                :minimum-results-for-search="Infinity"
                select2-id="select2_showing_scope_type"
              />
            </div>
            <div class="d-flex flex-wrap my-1">
              <label class="form-check form-switch form-check-custom form-check-solid me-8">
                <span
                  v-if="isSharingUpdating"
                  class="spinner-border spinner-border-sm text-primary align-middle me-2"
                />
                <input
                  v-model="isSharing"
                  class="form-check-input w-30px h-20px"
                  type="checkbox"
                  :disabled="isSharingUpdating"
                  @change="onSharing"
                />
                <span class="form-check-label text-muted fs-7">Sharing</span>
              </label>
              <px-dropdown
                :is-default-btn-hidden="true"
                btn-classes="btn btn-sm btn-primary dropdown-toggle"
                title="Actions"
                :is-icon-hidden="true"
                :items="[
                  { title: 'Add Adjustment', action: addNewAdjustment },
                  { title: 'Add Payment', action: addNewPayment },
                  { title: 'Add Payment Due', action: paymentDueImmediately },
                  { title: 'Share', action: share },
                  {
                    title: 'Generate PDF',
                    disabled: isPdfInProcess,
                    inProcess: isPdfInProcess,
                    action: makeAndDownloadPdf,
                  },
                ]"
              />
            </div>
          </div>

          <pxs-single-contract
            v-for="(showedContract, index) in showedContracts"
            :key="showedContract.id"
            :class="[{ 'border-top mt-10 pt-10': index !== 0 }]"
            :subject="item"
            :scope-type="showedContract.attributes.scope_type"
            @onContractLoaded="setScopeType"
          />

          <px-new-modal
            :is-visible="isAddNewAdjustmentModalOpened"
            :close-modal="closeAddNewAdjustmentModal"
            modal-size="md"
          >
            <template #title>
              <h3>New Adjustment</h3>
            </template>
            <template #content>
              <pxm-new-adjustment :contracts="statements" @onClose="closeAddNewAdjustmentModal" />
            </template>
          </px-new-modal>

          <px-new-modal :is-visible="isAddNewPaymentModalOpened" :close-modal="closeAddNewPaymentModal" modal-size="md">
            <template #title>
              <h3>New Payment</h3>
            </template>
            <template #content>
              <pxm-payment :contracts="statements" @onClose="closeAddNewPaymentModal" />
            </template>
          </px-new-modal>

          <px-new-modal
            :is-visible="isPaymentDueImmediatelyModalOpened"
            :close-modal="closePaymentDueImmediatelyModal"
            modal-size="md"
          >
            <template #title>
              <h3>Payment Due</h3>
            </template>
            <template #content>
              <pxm-payment-due-immediately :contracts="statements" @onClose="closePaymentDueImmediatelyModal" />
            </template>
          </px-new-modal>

          <px-new-modal modal-size="mw-650px" :is-visible="isShareModalOpened" :close-modal="closeShareModal">
            <template #title>
              <h3>Share</h3>
            </template>
            <template #content>
              <pxm-share-compact
                :item-id="item.id"
                :code="item.attributes.code"
                :secret="item.attributes.secret"
                @onClose="closeShareModal"
              />
            </template>
          </px-new-modal>
        </template>
        <px-placeholder v-else />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import PxsSingleContract from '@/views/projects/views/sections/PxsSingleContract';

import PxmNewAdjustment from '@/modals/PxmNewAdjustment';
import PxmPayment from '@/modals/PxmPayment';
import PxmPaymentDueImmediately from '@/modals/PxmPaymentDueImmediately';
import PxmShareCompact from '@/modals/PxmShareCompact';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    PxsSingleContract,
    PxmNewAdjustment,
    PxmPayment,
    PxmPaymentDueImmediately,
    PxmShareCompact,
  },
  computed: {
    ...mapGetters({
      hasSigned: 'projectsSingle/hasSigned',
      statements: 'projectsSingle/statements',
      pdfFile: 'singleProject/pdfFile',
      pdfReady: 'singleProject/pdfReady',
      message: 'singleProject/message',
      statementsAdjustments: 'singleProject/statementsAdjustments',
    }),
    showedContracts() {
      const contracts = [];

      if (
        this.showingScopeType === 'single-project-payment-showing-scope-type-item-landscaping' ||
        this.showingScopeType === 'single-project-payment-showing-scope-type-item-summary'
      ) {
        contracts.push({
          id: 'single-project-payment-showed-contracts-1001',
          attributes: {
            scope_type: 'Landscaping',
          },
        });
      }

      if (
        this.showingScopeType === 'single-project-payment-showing-scope-type-item-swimming-pool' ||
        this.showingScopeType === 'single-project-payment-showing-scope-type-item-summary'
      ) {
        contracts.push({
          id: 'single-project-payment-showed-contracts-1002',
          attributes: {
            scope_type: 'Swimming Pool',
          },
        });
      }

      if (
        this.showingScopeType === 'single-project-payment-showing-scope-type-item-woodworking' ||
        this.showingScopeType === 'single-project-payment-showing-scope-type-item-summary'
      ) {
        contracts.push({
          id: 'single-project-payment-showed-contracts-1003',
          attributes: {
            scope_type: 'Woodworking',
          },
        });
      }

      return contracts;
    },
  },
  data() {
    return {
      showingScopeType: 'single-project-payment-showing-scope-type-item-summary',
      isAddNewAdjustmentModalOpened: false,
      isAddNewPaymentModalOpened: false,
      isPaymentDueImmediatelyModalOpened: false,
      isShareModalOpened: false,
      isSharing: false,
      isSharingUpdating: false,
      isPdfInProcess: false,
      scopeTypes: [
        {
          id: 'single-project-payment-showing-scope-type-item-summary',
          attributes: {
            title: 'Summary',
          },
        },
      ],
    };
  },
  mounted() {
    this.loading(async () => {
      const fieldsArr = ['number', 'version', 'scope_type'];

      await this.actionStatements({ id: this.item.id, fields: this.arrayToString(fieldsArr) });
      await this.actionHasSigned({ fields: 'status', subject_id: this.item.id });

      this.isSharing = this.item.attributes.sharing || false;
    });
  },
  methods: {
    ...mapActions({
      actionHasSigned: 'projectsSingle/hasSigned',
      actionStatements: 'projectsSingle/statements',
      actionProjectUpdate: 'projects/update',
      actionUpdateStatements: 'singleProject/updateStatements',
      actionProjectPaymentMakePdf: 'singleProject/makePdf',
      actionUpdateStatementContract: 'singleProject/updateStatementContract',
      actionAddStatementAdjustments: 'singleProject/addStatementAdjustments',
      actionAddStatementPayments: 'singleProject/addStatementPayments',
    }),
    ...mapMutations({
      mutationSetPdfFile: 'singleProject/SET_PDF_FILE',
      mutationSetPdfReady: 'singleProject/SET_PDF_READY',
    }),
    setScopeType(data) {
      if (!data) return false;
      if (this.scopeTypes.find((el) => el.attributes.title === data.attributes.scope_type)) return false;

      this.scopeTypes = [
        ...this.scopeTypes,
        {
          id: `single-project-payment-showing-scope-type-item-${data.attributes.scope_type
            .split(' ')
            .join('-')
            .toLowerCase()}`,
          attributes: {
            title: data.attributes.scope_type,
          },
        },
      ];
    },
    async onSharing() {
      this.isSharingUpdating = true;

      await this.actionProjectUpdate({ id: this.item.id, params: { sharing: this.isSharing } });
      await this.actionUpdateStatementContract({ sharing: this.isSharing });

      setTimeout(() => {
        this.isSharingUpdating = false;
      }, 500);
    },
    async makePdf() {
      this.isPdfInProcess = true;

      const newPdf = await this.actionProjectPaymentMakePdf(this.item.id);

      await this.mutationSetPdfFile(newPdf.data.pdf_file);
      await this.mutationSetPdfReady(true);
      await this.v_toast(this.message?.status, 'Generated successfully!', () => {
        this.isPdfInProcess = false;
      });
    },
    async makeAndDownloadPdf() {
      await this.makePdf();
      await this.downloadFile(this.pdfFile);
    },
    downloadFile(url) {
      this.downloadPdfFromTab(url, 'project');
    },
    // New adjustment modal
    addNewAdjustment() {
      this.openAddNewAdjustmentModal();
    },
    openAddNewAdjustmentModal() {
      this.isAddNewAdjustmentModalOpened = true;
    },
    closeAddNewAdjustmentModal(data) {
      if (data) {
        this.actionAddStatementAdjustments(data);
        this.actionUpdateStatements({
          id: data.data.data.attributes.proposal_scope.data.id,
          scopeType: data.data.data.attributes.proposal_scope.data.attributes.scope_type,
        });
      }

      this.isAddNewAdjustmentModalOpened = false;
    },
    // New payment modal
    addNewPayment() {
      this.openAddNewPaymentModal();
    },
    openAddNewPaymentModal() {
      this.isAddNewPaymentModalOpened = true;
    },
    closeAddNewPaymentModal(data) {
      if (data) {
        this.actionAddStatementPayments(data);
        this.actionUpdateStatements({
          id: data.data.data.attributes.proposal_scope.data.id,
          scopeType: data.data.data.attributes.proposal_scope.data.attributes.scope_type,
        });
      }

      this.isAddNewPaymentModalOpened = false;
    },
    // Payment due immediately modal
    paymentDueImmediately() {
      this.openPaymentDueImmediatelyModal();
    },
    openPaymentDueImmediatelyModal() {
      this.isPaymentDueImmediatelyModalOpened = true;
    },
    closePaymentDueImmediatelyModal(data) {
      if (data) {
        this.actionUpdateStatementContract(data);
      }

      this.isPaymentDueImmediatelyModalOpened = false;
    },
    // Share modal
    share() {
      this.openShareModal();
    },
    openShareModal() {
      this.isShareModalOpened = true;
    },
    closeShareModal() {
      this.isShareModalOpened = false;
    },
  },
};
</script>
